var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(_vm.canEdit)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-right"},[_c('BaseButton',{attrs:{"id":"btn-close","name":"btn-close","color":"secondary","title":"Cadastrar"},on:{"click":_vm.create}})],1)],1):_vm._e(),_c('BaseTableList',{attrs:{"title":"Listagem de dependentes cadastrados"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{attrs:{"dense":"","disable-sort":"","item-key":"id","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.document`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatCpf(item.document))+" ")]}},{key:`item.situation`,fn:function({ item }){return [_c('span',{class:_vm.getColor(item.situation)},[_vm._v(_vm._s(item.situation))])]}},{key:`item.action`,fn:function({ item }){return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.edit(item)}}},on),[_vm._v(" fa-solid fa-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Visualizar")])]):_vm._e(),(_vm.canEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteDependent(item)}}},on),[_vm._v(" fa-regular fa-trash-can ")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])]):_vm._e()]}}],null,true)})]},proxy:true}])}),_c('v-divider',{staticClass:"my-5"}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"text-right"},[_c('BaseButton',{attrs:{"id":"btn-back","name":"btn-back","outlined":"","color":"primary","title":"Voltar"},on:{"click":_vm.back}})],1)],1),(_vm.dialog)?_c('DialogDependent',{attrs:{"employeeId":_vm.employeeId,"customerId":_vm.customerId,"dialog":_vm.dialog,"title":_vm.dialogTitle,"edit":_vm.dialogEdit,"selectItem":_vm.selectItem},on:{"closeModal":_vm.closeModal}}):_vm._e(),(_vm.dialogDelete)?_c('DialogDelete',{attrs:{"title":"Exclusão de dependente","dialog":_vm.dialogDelete,"employeeId":_vm.employeeId,"employeeName":_vm.selectItemDelete.name,"dependentId":_vm.selectItemDelete.id},on:{"closeModal":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }