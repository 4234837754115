<template>
  <v-container>
    <v-row dense v-if="canEdit">
      <v-col class="text-right">
        <BaseButton
          id="btn-close"
          name="btn-close"
          color="secondary"
          title="Cadastrar"
          @click="create"
        />
      </v-col>
    </v-row>

    <BaseTableList title="Listagem de dependentes cadastrados">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.document`]="{ item }">
            {{ formatCpf(item.document) }}
          </template>

          <template v-slot:[`item.situation`]="{ item }">
            <span :class="getColor(item.situation)">{{ item.situation }}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom v-if="canEdit">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  class="mr-2"
                  small
                  @click="edit(item)"
                >
                  fa-solid fa-pencil
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>

            <v-tooltip bottom v-if="canEdit">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="error"
                  small
                  @click="deleteDependent(item)"
                >
                  fa-regular fa-trash-can
                </v-icon>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-divider class="my-5" />

    <v-row dense>
      <v-col class="text-right">
        <BaseButton
          id="btn-back"
          name="btn-back"
          outlined
          color="primary"
          title="Voltar"
          @click="back"
        />
      </v-col>
    </v-row>

    <DialogDependent
      v-if="dialog"
      :employeeId="employeeId"
      :customerId="customerId"
      :dialog="dialog"
      :title="dialogTitle"
      :edit="dialogEdit"
      :selectItem="selectItem"
      @closeModal="closeModal"
    />

    <DialogDelete
      v-if="dialogDelete"
      title="Exclusão de dependente"
      :dialog="dialogDelete"
      :employeeId="employeeId"
      :employeeName="selectItemDelete.name"
      :dependentId="selectItemDelete.id"
      @closeModal="closeModal"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { getColor, doTruncateText } from '@/helpers/utils';
import { formatCpf } from '@/helpers/formatting';
import { MovementService } from '@/services/api/movements';

export default {
  components: {
    DialogDependent: () => import('./dialogs/dialog-dependents'),
    DialogDelete: () => import('./dialogs/dialog-delete-dependent')
  },

  props: {
    contracts: {
      type: Array,
      require: true
    },
    editMode: {
      type: Boolean
    },
    customerId: {
      type: String,
      require: true
    },
    employeeId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    selectItemDelete: {},
    selectItem: {},
    dialogDelete: false,
    dialog: false,
    dialogEdit: false,
    dialogTitle: '',
    options: {},
    headers: [
      { text: 'Nome', value: 'name' },
      { text: 'Parentesco', value: 'kinship' },
      { text: 'CPF', value: 'document' },
      { text: 'Status', value: 'situation' },
      { text: '', value: 'action' }
    ]
  }),

  computed: {
    ...mapGetters({
      employee: 'movements/getEmployee'
    }),

    canEdit() {
      const situation =
        this.employee.situation === 'Ativo' ||
        this.employee.situation === 'Em análise na operadora';

      return situation;
    }
  },

  methods: {
    formatCpf,
    getColor,
    doTruncateText,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;

        const movementService = new MovementService();
        const { status, data } = await movementService.getDependents({
          page,
          take: itemsPerPage,
          employeeId: this.employeeId
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    create() {
      this.dialog = true;
      this.dialogEdit = false;
      this.dialogTitle = 'Adicionar dependente';
      this.selectItem = {};
    },

    edit(item) {
      this.dialog = true;
      this.dialogEdit = true;
      this.dialogTitle = 'Visualizar dependente';
      this.selectItem = Object.assign({}, item);
    },

    closeModal(search) {
      this.dialog = false;
      this.dialogEdit = false;
      this.dialogTitle = '';
      this.selectItem = {};

      this.dialogDelete = false;
      this.selectItemDelete = {};

      if (search) {
        this.search();
      }
    },

    deleteDependent(item) {
      this.dialogDelete = true;
      this.selectItemDelete = Object.assign({}, item);
    },

    back() {
      this.$emit('back');
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
